import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DisclaimerPage = () => {

  return (
    <Layout >
      <SEO title="Disclaimer" />
      <h1>Disclaimer</h1>
      <p><h5>This blog is not intended to give medical advice, I include what I find helpful and my own experiences with what I have used. Please consult with your doctor for any questions or concerns you have.</h5></p>
    </Layout>
  )
}

export default DisclaimerPage